<template>
    <a-modal visible=''
             :width="700"
             :mask="true"
             title="系统提示"
             @ok="QRCodeclose()"
             @cancel="cancel()">
        <a-row>
            <a-col :span="12" align="center">
                <div v-if="QRCodeType==1">
                    <a-icon type="check-circle"
                            :style="{ fontSize: '36px', color: '#1AAD19' }" />
                    <h1>推送成功</h1>
                    <br />
                </div>
                <a-row :gutter="10">
                    <a-col :span="18"> <a-input v-model="URL"></a-input></a-col>
                    <a-col :span="6">
                        <a-button type="link" @click="copy()"><a-icon type="link" />复制链接</a-button>
                    </a-col>
                </a-row>

                <p style="padding: 10px; font-size: 12px; color: #999">
                    您可以将活动链接复制到您的公众号菜单或自定义回复中
                </p>

            </a-col>
            <a-col :span="12" align="center">
                <div style="text-align: center">

                    <p style="margin: 10px">

                        <a :href="SRC" download="qrjpg.jpg" target="_blank"><img :src="SRC" style="width: 150px; height: 150px" /><br />下载二维码</a>
                    </p>


                </div>

                <!-- <a-button size="large" type="primary" @click="QRCodeclose()"
                        >确定</a-button> -->
            </a-col>
        </a-row>
    </a-modal>
</template>
<script>
    import http from "@/Plugin/Http.js";
    export default {
        name: "QRCode",
        data() {
            return {
                URL: "",
                SRC: "",
                visible: true,
            };
        },
        props: {
            //组件属性
            close: {
                type: Function,
                default: null,
            },
            ID: String,
            ClassID: String,
            QRCodeType: Number,
            hide: {
                type: Function,
                default: null,
            },
        },
        methods: {
            //方法
            copy() {
                var self = this;
                var Input = document.createElement("input");
                Input.value = self.URL;
                document.body.appendChild(Input);
                Input.select();
                document.execCommand("Copy");
                Input.className = "oInput";
                Input.style.display = "none";
                self.$message.success("链接复制成功");
            },
            QRCodeclose: function () {
                var self = this;
                var Mid=localStorage.Mid;
                if(Mid!=undefined)
                {
                    self.$router.push({name: "Store_DefaultConfiguration"})
                }
                else{
                    if (self.QRCodeType == 1) {
                        //console.log("rrrrrrrrrrrrrrrrrrrrrrrr");
                        //console.log(this.$route.query.InterActionID);
                        //console.log(self.ClassID);
                        if (self.ClassID == 8) {
                            var op1 = {
                                url: "/MarketingModule/MKTPunchClock/EditInteractionInfoPublishStatus",
                                data: {
                                    ID: self.ID,
                                    PublishStatus: 1,
                                },
                                OnSuccess: function (data) {
                                    console.log(data);
                                    self.close(true);
                                }
                            };
                            http.Post(op1);
                        }

                        var op = {
                            url: "/MarketingModule/Material/UpdateStatus",
                            data: {
                                ID: self.ID,
                                PublishStatus: 1,
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                self.close(true);
                            }
                        };
                        http.Post(op);

                    } else {
                        self.close(true);
                    }
                }
            },
            cancel: function (e) {
                console.log(e);
                var self = this;
                self.hide();
                //   self.visible = false;
            },
            loadData() {
                var self = this;
                var op = {
                    url: "/MarketingModule/M2/preview",
                    data: {
                        mid: self.ID,
                    },
                    OnSuccess: function (data) {
                        console.log(data);
                        self.URL = data.data.link;
                        self.SRC = data.data.imgsrc;
                    },
                };
                http.Post(op);
            },
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            //组件
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            //this.InterActionID = this.$route.query.InterActionID;
            this.loadData();
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        },
    };
</script>
<style scoped>
</style>